import React from 'react';
import HomePage from '../components/HomePage';
/**
 * Application home page component
 * @returns {JSX}
 */
const homePage = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default homePage;
